/* Animation styles for the speaker cards */
.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

.block-speaker {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; /* Space between speaker blocks */
}

.speaker-img {
  position: relative;
}

.bio-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Same width as the image */
  background: rgba(0, 0, 0, 0.7); /* Transparent black background */
  color: white;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.block-speaker:hover .bio-container {
  opacity: 1;
  transform: translateY(0);
}

.block-info-2 {
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}

.block-speaker:hover .block-info-2 {
  opacity: 0; /* Hide name and designation */
}

/* Add space between rows of speakers */
.speakers .row {
  margin-bottom: 10px; /* Adjust to control space between rows */
}


.speakers-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 10px; /* Space between cards */
  max-width: 600px; /* Optional: control grid width */
  margin: 0 auto; /* Center the grid */
}

.speaker-card {
  position: relative;
  overflow: hidden;
}
