.hero-img img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  /* Adjust image size for smaller screens */
  .hero-logo-wrapper img {
    max-width: 60%;
    height: auto;
  }

  .title {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  /* Further scaling for extra-small screens */
  .hero-logo-wrapper img {
    max-width: 50%;
  }

  .title {
    font-size: 1.5rem;
  }
}
