/* Ticket Card Styles */
.title-lead {
  font-size: 1.125rem;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.block-tickets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.block-ticket {
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #001a1a;
  border-radius: 20px;
}

.block-ticket.active {
  border: 2px solid #e7a73b;
}

.block-ticket button {
  background: #e7a73b;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.block-ticket button:hover {
  background: #e7a73b
}

/* Flexbox Layout */

.col-sm-6 {
  margin-bottom: 20px;
}

.sub-title-0 {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.gradient-text {
  background: #e7a73b;
  background-clip: text;
  color: transparent;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.btn-quantity {
  background-color: #e7a73b;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 5px;
}

.btn-quantity:hover {
  background-color: #e7a73b;
}

.quantity {
  font-size: 1.2rem;
  margin: 0 10px;
}

/* End ticket styles */

.quantity-input {
  text-align: center;
}

.cart-list {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  font-size: 1rem;
  margin: 8px 0;
}

.cart-icon {
  position: relative;
  cursor: pointer;
  top: 0.15rem;
}

.cart-badge {
  background: #e7a73b;
  color: white;
  /* padding: 0.15rem 0.15rem;  */
  padding: 1px;
  width: 18px;
  height: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Position it absolutely in the container */
  top: 0rem; /* Moves the badge above the icon */
  left: 1rem; /* Moves the badge to the upper right of the cart */
  border-radius: 50%; /* Circular badge */
  font-size: 10px;
}
