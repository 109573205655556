.footer {
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0, 0, 0, 0.8));
    color: #fff;
    padding: 20px 0;
    text-align: center;
    margin-top: 75px;
  }
  
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .footer-logo-container {
    flex: 1;
  }
  
  .footer-logo-img {
    max-width: 150px;
  }
  
  .footer-links {
    flex: 2;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
  
  .footer-links li {
    margin-left: 20px;
    cursor: pointer;
  }
  
  .footer-links li:hover {
    text-decoration: underline;
  }
  