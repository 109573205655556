.contact-info {
    display: flex;
    align-items: center;
    background-color: #141414;
    line-height: 2.5;
}

.information .row {
    display: flex;
    flex-direction: column;
    gap: 50px ;
    max-width: 60%;
    margin: auto;
}
.information h1 {
    font-size: 30px;
}
.information p {
    font-size: 1rem;
}
.information .box-img {
   flex: 1;
}
.information .contact {
    text-align: left;
    flex: 1;
}
.dark-bg {
    background-color: #000;
}
.card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    justify-items: center;
}
.card {
    width: 100%;
    /* max-width: 300px; */
    overflow: hidden;
    margin: 10px;
    text-align: center;
}
.card p {
    margin: 2px 0;
}
.card-img {
    width: 400px;
    height: 500px;
    overflow: hidden;
    text-align: center;
    margin: auto;
}
.card-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
}
.card-img img:hover {
    transform: scale(1.2);
}
.faq-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    width: 100%;
}
  .faq-item {
    padding: 15px;
}
  .faq-question {
    font-size: 1.1rem;
    font-weight: bold;
}
.faq-question h3 {
    text-transform: capitalize;
}
.faq-answer {
    margin-top: 10px;
    font-size: 1rem;
    color: #aaa;
}
  

@media (max-width: 991px) {
    .card-container {
      grid-template-columns: repeat(1, 1fr);
    }

  }
@media only screen and (max-width: 768px){
    .information {
     flex-direction: column;
    }
    .information .contact {
        padding: 40px 0;
    }
}