/* PrintBadges.css */
.print-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.badge {
  width: 300px;
  height: 200px;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

/* Header style */
.badge-header h1 {
  margin: 0;
  font-size: 22px;
}

/* Participant information */
.badge-body {
  margin-top: 10px;
}
.badge-body p {
  margin: 4px 0;
  font-size: 16px;
}

/* Divider style */
.badge-divider {
  margin: 10px 0;
}

/* Ticket type style */
.badge-ticket p {
  font-weight: bold;
  font-size: 18px;
}

/* Hide everything except our print container when printing */
@media print {
  body * {
    visibility: hidden;
  }
  .print-container,
  .print-container * {
    visibility: visible;
  }
  .print-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
