.countdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.countdown div {
  padding: 35px;
}

.counter {
  color: #cdcdcd;
  border-radius: 20px;
  text-align: center;
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
}

.counter b {
  font-size: 42px;
}

.counter span {
  font-size: 14px;
  color: #cdcdcd;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .countdown {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .countdown div {
    padding: 20px;
  }
  
  .counter {
    min-width: 150px;
    flex-grow: 1;
    margin: 0;
  }

  .contact {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    letter-spacing: 2px;
  }
}
