/* src/components/whatsapp.css */
.whatsapp-chat {
  position: fixed;
  bottom: 35px;
  right: 50px;
  z-index: 9999;
}

.whatsapp-button {
  background-color: #25d366; /* WhatsApp green */
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.whatsapp-button .my-float {
  margin: 0;
}

.whatsapp-button:focus {
  outline: none;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .whatsapp-chat {
    bottom: 20px; /* Adjust bottom position */
    right: 20px; /* Adjust right position */
  }

  .whatsapp-button {
    padding: 12px; /* Reduce button size for mobile */
    font-size: 20px; /* Adjust icon size */
  }
}

@media (max-width: 480px) {
  .whatsapp-chat {
    bottom: 15px; /* Adjust bottom further for smaller screens */
    right: 15px; /* Adjust right further for smaller screens */
  }

  .whatsapp-button {
    padding: 10px; /* Further reduce button size */
    font-size: 18px; /* Further adjust icon size */
  }
}
