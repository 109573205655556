/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: rgba(37, 37, 37, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 80%; /* Adjust width to 80% of the viewport */
  max-width: 600px; /* Set max-width to 600px */
  max-height: 90vh; /* Set a max height of 90% of the viewport height */
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto; /* Allow scroll if the content is too long */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

.modal-body {
  display: flex;
  gap: 10px; /* Reduce gap between image and text */
  align-items: flex-start; /* Align the items to the top */
}

.speaker-items-column {
  display: flex;
  justify-content: center;
  align-items: center; /* Center the image horizontally and vertically */
}

.modal-speaker-image {
  width: 45%; /* Set a fixed width for the image */
  height: 45%; /* Set a fixed height for the image */
  object-fit: cover;
  border-radius: 10px;
}

.speaker-info-column {
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure text starts from the top */
}

.speaker-info-column h3 {
  margin-bottom: 10px;
  font-size: 1.25rem;
}

.speaker-info-column p {
  text-align: justify;
  line-height: 1.6;
  color: #dcdcdc;
  margin-right: 40px;
}
