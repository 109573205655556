/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
}

.hero {
  padding-top: 80px;
}

.header {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  color: white;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo-container {
  flex: 0 0 auto;
}

.logo-img {
  max-height: 50px;
  width: auto;
}

.toggle-mobile-but {
  display: none;
  cursor: pointer;
}

.main-nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
}

.main-nav li {
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.main-nav li:hover {
  color: #f2a900; /* Change to your desired hover color */
}

.main-nav li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f2a900; /* Underline color */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.main-nav li:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Menu active (for mobile) */
.main-nav ul.active {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.95);
  position: absolute;
  top: 60px;
  right: 0;
  width: 100%;
  padding: 10px 0;
}

.main-nav li {
  text-align: center;
  padding: 15px 0;
}

/* Responsive Styles */
@media (max-width: 1000px) {
  .header-row {
    flex-direction: row;
  }

  .toggle-mobile-but {
    display: flex;
    justify-content: flex-end;
  }

  .logo-container {
    flex: 1;
  }

  .main-nav ul {
    display: none;
  }

  .main-nav ul.active {
    display: flex;
  }

  .logo-img {
    max-height: 40px;
  }
}
