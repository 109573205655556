.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 1000;
  overflow: auto;
}

.modal-container {
  background: rgba(37, 37, 37, 0.9);
  padding: 20px;
  border-radius: 10px;
  max-width: 60%;
  /* max-width: 1000px; Increased width for a two-column layout */
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: row; /* Two-column layout */
  gap: 20px;
}

.cart-items-column,
.personal-info-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1; /* Each column should take up equal space */
}

.cart-items-column {
  /* max-width: 500px;   Limit the width of the cart column */
}

.cart-list-group-item {
  margin-top: 10px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 10px;
  /* width: 100%; */
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-controls button {
  width: 25px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  background: rgba(37, 37, 37, 0.9);
}

.personal-info-column {
  padding-left: 20px;
  border-left: 1px solid #fff;
}
.personal-info-column input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.personal-info-column select {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.btn-register {
  background: #e7a73b;
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Gray out when disabled */
.btn-register:disabled {
  background-color: #222323;
  color: #666666;
  cursor: not-allowed;
  border: 1px solid #666666;
}

/* ---------- */
.react-phone-number-input .PhoneInputCountry {
  background-color: red !important; /* Background color */
  border-radius: 8px; /* Rounded corners */
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
}

/* Customizing the country flags */
.react-phone-number-input
  .PhoneInputCountry
  .react-phone-number-input__country-flag {
  margin-right: 10px;
  border-radius: 50%; /* Circular flag */
}

/* Hover effect for country items */
.react-phone-number-input .PhoneInputCountry:hover {
  background-color: #000; /* Change background on hover */
}

/* Focus effect for the dropdown (when country code is selected) */
.react-phone-number-input .PhoneInputCountry:focus {
  border-color: #4caf50;
}

/* Styling the search bar if enabled */
.react-phone-number-input .PhoneInputSearch {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Custom style for the dropdown container */
.react-phone-number-input .PhoneInputDropdown {
  max-height: 300px !important; /* Limit the dropdown height */
  overflow-y: auto; /* Allow scrolling if content overflows */
  background-color: #000 !important; /* Background color of dropdown */
  border: 1px solid #ddd; /* Border around the dropdown */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow */
  width: 100%; /* Full width of the dropdown */
}

/* Customizing the selected country flag */
.react-phone-number-input
  .PhoneInputCountry
  .react-phone-number-input__country-flag {
  width: 20px;
  height: 20px;
}
/* ---------- */
@media (max-width: 1200px) {
  .modal-container {
    max-width: 80%;
  }
}

@media (max-width: 991px) {
  .modal-container {
    min-width: 90%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .modal-container {
    /* max-width: 100%; */
  }

  .modal-body {
    flex-direction: column; /* Stack columns vertically on smaller screens */
  }

  .cart-items-column,
  .personal-info-column {
    width: 100%;
  }
  .personal-info-column {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #fff;
    padding-left: 0;
    border-left: none;
  }
}

.PhoneInputCountry {
  padding: 10px;
  height: 45px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.08);
}

