.about {
  position: relative;
  overflow: hidden;
}

.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px; /* Adjust height as needed */
  background: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.about .container {
  position: relative;
  z-index: 2; /* Ensure content is above the gradient overlay */
}
